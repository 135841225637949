import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weekDay',
  standalone: true
})
export class WeekDayPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return new Date(+value);
  }
}
