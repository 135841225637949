import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayPipe',
  standalone: true
})
export class DayPipe implements PipeTransform {
  public transform(value: any): any {
    if (value) {
      const items = value.split(':');
      const val = new Date();
      const rest = items[1].split(' ');
      val.setHours(rest[1] === 'PM' ? +items[0] + 12 : +items[0]);
      val.setMinutes(+rest[0]);
      return val;
    }
    return '';
  }
}
